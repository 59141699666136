document.addEventListener('DOMContentLoaded', () => {

  /**
   * 指定された DOM の子要素を全て削除してから img 要素を挿入する
   * img 要素に設定される src 属性は src 引数で設定可能
   * src 引数を空にすると内容を空にするだけで子要素を新たに作成しない
   * @param {HTMLElement} parent
   * @param {string} src
   * @private
   */
  const _changeIcon = (parent, src = '') => {
    //一旦内容物を全て削除
    parent.textContent = '';

    //src が指定されていなければ空にするだけで終了
    if (src === '') {
      return;
    }

    //指定された src 属性を持つ img 要素を生成
    const image = document.createElement('img');
    image.src = src;

    //子要素として生成した img 要素を設定
    parent.appendChild(image);
  };

  /**
   * サーバーから返ってきたエラーメッセージを実際の DOM に描写する
   * @param errors
   * @private
   */
  const _parseErrorMessages = errors => {
    //そもそも渡された errors がオブジェクトでなければ何もしない
    if (
        typeof errors !== 'object' ||
        errors === null
    ) {
      return;
    }

    //対象ごとにエラーを描写
    for (const name of Object.keys(errors)) {
      //エラーメッセージ表示領域・アイコン表示領域を捕捉
      const errorContainer = document.querySelector('.js-errors[data-name="'+name+'"]');
      const iconContainer = document.querySelector('.js-icons[data-name="'+name+'"]');

      //エラーアイコンを設定
      if (iconContainer) {
        _changeIcon(iconContainer, siteUrl+'/images/formIconNg.svg');
      }

      //エラーメッセージ出力用の DOM が無い or エラーメッセージが Array でも String でもなければスルー
      if (
          ! errorContainer ||
          (! Array.isArray(errors[name]) && typeof errors[name] !== 'string')
      ) {
        continue;
      }

      //エラーを描写
      errorContainer.textContent = errors[name] === 'string' ? errors[name] : errors[name].join(' ');
    }
  };

  /**
   * 入力に対してバリデーションをサーバーに依頼し、結果を描写する
   * input 要素などに対して change イベントや input イベントを addEventListener で監視することを想定
   * 監視対象の DOM には data-error, data-icon 属性が必須で、更にそれを id 属性に持つ DOM が必要
   * @param event
   * @return {Promise<void>}
   */
  const _changeValidation = async event => {
    //対象が <input> or <textarea> or <select> か確認
    if (
      ! (event.target instanceof HTMLInputElement) &&
      ! (event.target instanceof HTMLTextAreaElement) &&
      ! (event.target instanceof HTMLSelectElement)
    ) {
      return;
    }

    //対象が data-error & data-icon を持っているか確認
    if (
        ! ('error' in event.target.dataset) ||
        ! ('icon' in event.target.dataset)
    ) {
      return;
    }

    //エラーメッセージ表示領域・アイコン表示領域を捕捉
    const errorContainer = document.getElementById(event.target.dataset.error);
    const iconContainer = document.getElementById(event.target.dataset.icon);

    //エラーメッセージ & アイコン描画対象が無ければここで処理中断
    if ( ! errorContainer || ! iconContainer) {
      return;
    }

    //エラーメッセージ & アイコン描画を初期化する
    iconContainer.textContent = '';
    errorContainer.textContent = '';

    //バリデーションをサーバーへ依頼する
    try {
      //通信開始
      const response = await fetch(siteUrl+'/entry/valid/'+event.target.name, {
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
        },
        body: new FormData(form)
      });

      //レスポンス異常発生
      if ( ! response.ok) {
        //メッセージを JSON としてパース
        const json = await response.json();

        //もし errors が見つかったら例外を投げる
        if (
            typeof json.errors === 'object' &&
            json.errors !== null
        ) {
          throw json;
        }

        //もし message が見つかったら例外を投げる
        if (typeof json.message === 'string') {
          throw new Error(json.message);
        }

        //エラーメッセージを文字列に変換して例外を投げる
        throw new Error('エラーが発生しました。しばらくしてから再度お試しください。');
      }

      //成功ステータスアイコンにする
      _changeIcon(iconContainer, siteUrl+'/images/formIconCheck.svg');
    }

    //サーバーとの通信中、なんらかのエラーが発生
    catch (error) {
      //失敗ステータスアイコンにする
      _changeIcon(iconContainer, siteUrl+'/images/formIconNg.svg');

      //サーバーから得られた各バリデーション
      if (
          typeof error.errors === 'object' &&
          error.errors !== null
      ) {
        _parseErrorMessages(error.errors);
      }

      //恐らく通信途絶
      else if (error instanceof TypeError) {
        errorContainer.textContent = 'ネットワーク接続に失敗しました。';
      }

      //恐らく意図的な中断
      else if (error instanceof DOMException) {
        errorContainer.textContent = 'ネットワーク接続が中断されました。';
      }

      //恐らくカスタムエラー(try 節中、意図的に throw している部分)
      else if (error instanceof Error) {
        errorContainer.textContent = error.message;
      }

      //不明
      else {
        errorContainer.textContent = 'エラーが発生しました。しばらくしてから再度お試しください。'
      }
    }
  };

  /**
   * サーバーサイドは
   * ・パスワード + メールアドレス
   * フロントエンドは
   * ・OAuth 連携 + メールアドレス
   * という違ったフローで動いているかを検知し、違っていたら画面をリロードする
   * @returns {Promise<void>}
   * @private
   */
  const _reloadDifferentModeCache = async () => {
    //フロントエンドが OAuth 認証フローでない場合は何もしない
    if (document.querySelector('[name="password"]')) {
      return;
    }

    //サーバーに現在のモードを問合せ(キャッシュ無効化のため query を付与)
    const response = await fetch(siteUrl+'/entry/mode?time='+(new Date()).getTime());

    //レスポンスが異常であれば何もしない
    if ( ! response.ok) {
      return;
    }

    //パース
    const result = await response.json();

    //応答に mode が無い、または oauth モードなら何もしない
    if ( ! ('mode' in result) || result.mode !== 'password') {
      return;
    }

    //ページをリロード
    window.location.reload();
  };

  //js-listenInput を class に持つ DOM を得る
  const listenInput = document.getElementsByClassName('js-listenInput');

  //change & blur イベントを購読し、逐次バリデーションを行う
  for (let i = 0, max = listenInput.length; i < max; i = (i + 1) | 0) {
    listenInput.item(i).addEventListener('change', _changeValidation);
    listenInput.item(i).addEventListener('blur', _changeValidation);
  }

  //決定ボタンとフォーム、モーダルウィンドウを捕捉
  const submit = document.getElementById('submit');
  const form = document.getElementById('form');
  const modal = document.getElementById('modal');

  //決定ボタン・フォーム・モーダルが存在しなければ何もしない
  if ( ! submit || ! form || ! modal) {
    return;
  }

  //決定ボタンを押した際にサーバーへ通信を開始する
  submit.addEventListener('click', async () => {
    //サブミットボタン付近のエラーメッセージを捕捉
    const submitError = document.getElementById('submitError');

    //一旦サブミットボタン付近のエラーメッセージを初期化
    if (submitError) {
      submitError.textContent = '';
    }

    //一旦各エラーメッセージを初期化
    const errors = document.getElementsByClassName('js-errors');
    for (let i = 0, max = errors.length; i < max; i = (i + 1) | 0) {
      errors.item(i).textContent = '';
    }

    //サーバーに問い合わせを行う
    try {
      //通信開始
      const response = await fetch(siteUrl+'/entry/send', {
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
        },
        body: new FormData(form)
      });

      //レスポンス異常発生
      if ( ! response.ok) {
        //メッセージを JSON としてパース
        const json = await response.json();

        //もし errors が見つかったら例外を投げる
        if (
            typeof json.errors === 'object' &&
            json.errors !== null
        ) {
          throw json;
        }

        //もし message が見つかったら例外を投げる
        if (typeof json.message === 'string') {
          throw new Error(json.message);
        }

        //エラーメッセージを文字列に変換して例外を投げる
        throw new Error('エラーが発生しました。しばらくしてから再度お試しください。');
      }

      //成功画面を表示
      modal.classList.add('isDsply');
    }

    //サーバーとの通信中、なんらかのエラーが発生
    catch (error) {
      //サーバーから得られた各バリデーション
      if (
          typeof error.errors === 'object' &&
          error.errors !== null
      ) {
        _parseErrorMessages(error.errors);
      }

      //恐らく通信途絶
      else if (error instanceof TypeError) {
        submitError.textContent = 'ネットワーク接続に失敗しました。';
      }

      //恐らく意図的な中断
      else if (error instanceof DOMException) {
        submitError.textContent = 'ネットワーク接続が中断されました。';
      }

      //恐らくカスタムエラー(try 節中、意図的に throw している部分)
      else if (error instanceof Error) {
        submitError.textContent = error.message;
      }

      //不明
      else {
        submitError.textContent = 'エラーが発生しました。しばらくしてから再度お試しください。'
      }
    }
  });

  //モーダルを閉じるアクションを登録したい DOM を得る
  const modalCloseButtons = document.getElementsByClassName('js-modalClose');

  //click イベントを購読し、モーダルを閉じる
  for (let i = 0, max = modalCloseButtons.length; i < max; i = (i + 1) | 0) {
    modalCloseButtons.item(i).addEventListener('click', event => {
      //イベントが起こった要素が js-modalClose クラスを持っていなかったら何もしない
      if ( ! event.target instanceof HTMLElement || ! event.target.classList.contains('js-modalClose')) {
        return;
      }

      //削除する
      modal.classList.remove('isDsply');
    });
  }

  //フォームのサブミットイベントを無効化
  form.addEventListener('subtmit', event => { event.preventDefault() });

  //1. OAuth 認証リダイレクトから戻る
  //2. 新規会員登録画面(?oauth=1 無し URL)に遷移
  //3. 戻るボタンで 1. の画面に戻る
  //というフローを経ると、 3. でブラウザがキャッシュから html を読み出し、
  //サーバー側では OAuth モードが切れているにも関わらずメールアドレス欄しか表示されない
  //メールアドレスのみ送信してもサーバー側ではパスワードが必須になっているので先に進めない
  //これを回避するため、 OAuth モードが既に切れているかサーバーに問合せ、切れているならリロード
  const _checkMode = () => {
    //チェック
    _reloadDifferentModeCache();

    //1.5 秒後に再起動
    setTimeout(_checkMode, 1500);
  };
  _checkMode();
});
